import React from "react";

import Layout from "../layout/layout";
import VirtualboxProcess from "../components/virtualbox-process";

const VirtualBox = () => {

  return (
    <Layout>
      <VirtualboxProcess />
    </Layout>
  );
};

export default VirtualBox;
